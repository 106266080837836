.Completed {
    color: black;
    font-size: 7vh;
     text-align: center;
    padding: 0px;
    margin: 0px;
}

.CompletedSubText{
    font-style: normal;
    font-weight: normal;
    font-size: 4vh;
    text-align: center;
    margin: 0px;
    padding: 0px 0px 80px 0px;

    color: black;
}

.App {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.finishedImage {
    max-width: 90%;
    max-height: 500px;
}