.listCard {
    width: 70%;
    margin: 30px auto;
}

.cardHeader {
  background-color: #DADADA;
}

.card-body {
  background-color: #DADADA;
}

.squareHeader {
    font-size:5vh;
    padding:0px;
    text-align: center;
}

.modal-content {

  background-color: #DADADA !important;
}


.originalImageTick {
  display: inline-block;
  height: 250px;
  vertical-align:top;
}





input[type=range].slider {
    width: 100%;
    margin: 11.3px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type=range].slider:focus {
    outline: none;
  }
  input[type=range].slider::-webkit-slider-runnable-track {
    background: #3259a9;
    border: 0.2px solid #000101;
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  input[type=range].slider::-webkit-slider-thumb {
    margin-top: -11.5px;
    width: 15px;
    height: 31px;
    background: #60d83d;
    border: 1.9px solid #160000;
    border-radius: 7px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type=range].slider:focus::-webkit-slider-runnable-track {
    background: #3761b9;
  }
  input[type=range].slider::-moz-range-track {
    background: #3259a9;
    border: 0.2px solid #000101;
    border-radius: 1.3px;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  input[type=range].slider::-moz-range-thumb {
    width: 15px;
    height: 31px;
    background: #60d83d;
    border: 1.9px solid #160000;
    border-radius: 7px;
    cursor: pointer;
  }
  input[type=range].slider::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 11.3px 0;
    color: transparent;
    width: 100%;
    height: 8.4px;
    cursor: pointer;
  }
  input[type=range].slider::-ms-fill-lower {
    background: #2d5199;
    border: 0.2px solid #000101;
    border-radius: 2.6px;
  }
  input[type=range].slider::-ms-fill-upper {
    background: #3259a9;
    border: 0.2px solid #000101;
    border-radius: 2.6px;
  }
  input[type=range].slider::-ms-thumb {
    width: 15px;
    height: 31px;
    background: #60d83d;
    border: 1.9px solid #160000;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
  input[type=range].slider:focus::-ms-fill-lower {
    background: #3259a9;
  }
  input[type=range].slider:focus::-ms-fill-upper {
    background: #3761b9;
  }
  /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    input[type=range].slider {
      margin: 0;
      /*Edge starts the margin from the thumb, not the track as other browsers do*/
    }
  }





  .topTickMarks {
    background-color: #ffffff;
    background-image: 
      linear-gradient(90deg, 
        rgba(0, 0, 0) 0, 
        rgb(0, 0, 0) 2%, 
        transparent 2%
      ), 
      linear-gradient(90deg, 
        transparent 98%,
        rgba(0, 0, 0) 99%, 
        rgb(0, 0, 0) 100%
      ),
      linear-gradient(180deg, 
        #ffffff 50%, 
        transparent 50%
      ), 
      linear-gradient(90deg, 
        transparent 50%, 
        rgba(73, 73, 73, 0.5) 50%, 
        rgba(73, 73, 73, 0.5) 52%, 
        transparent 52%
      ), 
      linear-gradient(180deg, 
        #ffffff 70%, 
        transparent 70%
      ), 
      linear-gradient(90deg, 
        transparent 10%,
        rgba(73, 73, 73, 0.4) 10%, 
        rgba(73, 73, 73, 0.4) 12%, 
        transparent 12%, 
        
        transparent 20%,
        rgba(73, 73, 73, 0.4) 20%, 
        rgba(73, 73, 73, 0.4) 22%, 
        transparent 22%, 
        
        transparent 30%, 
        rgba(73, 73, 73, 0.4) 30%,
        rgba(73, 73, 73, 0.4) 32%, 
        transparent 32%, 
        
        transparent 40%, 
        rgba(73, 73, 73, 0.4) 40%, 
        rgba(73, 73, 73, 0.4) 42%, 
        transparent 42%, 
        
        transparent 60%, 
        rgba(73, 73, 73, 0.4) 60%, 
        rgba(73, 73, 73, 0.4) 62%, 
        transparent 62%, 
        
        transparent 70%, 
        rgba(73, 73, 73, 0.4) 70%, 
        rgba(73, 73, 73, 0.4) 72%, 
        transparent 72%, 
        
        transparent 80%, 
        rgba(73, 73, 73, 0.4) 80%, 
        rgba(73, 73, 73, 0.4) 82%, 
        transparent 82%, 
        
        transparent 90%, 
        rgba(73, 73, 73, 0.4) 90%, 
        rgba(73, 73, 73, 0.4) 92%, 
        transparent 92%
      ) ;
    background-size: 62.5px 20px;
    background-repeat: repeat-x;
    min-height: 20px;
    
    /* only needed for labels */
    max-width: 250px;
    white-space:nowrap;
    font-size:0;
    margin:0;
    margin: auto;
    padding:0;
  }

  .leftTickMarks {
    background-color: #ffffff;
    background-image: 
    linear-gradient(0deg, 
    rgb(0, 0, 0) 0, 
    rgb(0, 0, 0) 2%, 
    transparent 2%
  ), 
  linear-gradient(0deg, 
        transparent 98%,
        rgba(0, 0, 0) 99%, 
        rgb(0, 0, 0) 100%
      ),
  linear-gradient(90deg, 
    #ffffff 50%, 
    transparent 50%
  ), 
  linear-gradient(0deg, 
    transparent 50%, 
    rgba(73, 73, 73, 0.5) 50%, 
    rgba(73, 73, 73, 0.5) 52%, 
    transparent 52%
  ), 
  linear-gradient(90deg, 
    #ffffff 70%, 
    transparent 70%
  ), 
  linear-gradient(0deg, 
    transparent 10%,
    rgba(73, 73, 73, 0.4) 10%, 
    rgba(73, 73, 73, 0.4) 12%, 
    transparent 12%, 
    
    transparent 20%,
    rgba(73, 73, 73, 0.4) 20%, 
    rgba(73, 73, 73, 0.4) 22%, 
    transparent 22%, 
    
    transparent 30%, 
    rgba(73, 73, 73, 0.4) 30%,
    rgba(73, 73, 73, 0.4) 32%, 
    transparent 32%, 
    
    transparent 40%, 
    rgba(73, 73, 73, 0.4) 40%, 
    rgba(73, 73, 73, 0.4) 42%, 
    transparent 42%, 
    
    transparent 60%, 
    rgba(73, 73, 73, 0.4) 60%, 
    rgba(73, 73, 73, 0.4) 62%, 
    transparent 62%, 
    
    transparent 70%, 
    rgba(73, 73, 73, 0.4) 70%, 
    rgba(73, 73, 73, 0.4) 72%, 
    transparent 72%, 
    
    transparent 80%, 
    rgba(73, 73, 73, 0.4) 80%, 
    rgba(73, 73, 73, 0.4) 82%, 
    transparent 82%, 
    
    transparent 90%, 
    rgba(73, 73, 73, 0.4) 90%, 
    rgba(73, 73, 73, 0.4) 92%, 
    transparent 92%
  );
    background-size: 20px 62.5px;
    background-repeat: repeat-y;
    height: 250px;
    
    /* only needed for labels */
    width: 20px;
    font-size:0;
    margin:0;
    margin: auto;
    display: inline-block;

    padding:0;
  }

  .rightTickMarks {
    background-color: #ffffff;
    background-image: 
    linear-gradient(180deg, 
    rgb(0, 0, 0) 0, 
    rgb(0, 0, 0) 2%, 
    transparent 2%
  ), 
  linear-gradient(180deg, 
        transparent 98%,
        rgba(0, 0, 0) 99%, 
        rgb(0, 0, 0) 100%
      ),
  linear-gradient(270deg, 
    #ffffff 50%, 
    transparent 50%
  ), 
  linear-gradient(0deg, 
    transparent 50%, 
    rgba(73, 73, 73, 0.5) 50%, 
    rgba(73, 73, 73, 0.5) 52%, 
    transparent 52%
  ), 
  linear-gradient(270deg, 
    #ffffff 70%, 
    transparent 70%
  ), 
  linear-gradient(180deg, 
    transparent 10%,
    rgba(73, 73, 73, 0.4) 10%, 
    rgba(73, 73, 73, 0.4) 12%, 
    transparent 12%, 
    
    transparent 20%,
    rgba(73, 73, 73, 0.4) 20%, 
    rgba(73, 73, 73, 0.4) 22%, 
    transparent 22%, 
    
    transparent 30%, 
    rgba(73, 73, 73, 0.4) 30%,
    rgba(73, 73, 73, 0.4) 32%, 
    transparent 32%, 
    
    transparent 40%, 
    rgba(73, 73, 73, 0.4) 40%, 
    rgba(73, 73, 73, 0.4) 42%, 
    transparent 42%, 
    
    transparent 60%, 
    rgba(73, 73, 73, 0.4) 60%, 
    rgba(73, 73, 73, 0.4) 62%, 
    transparent 62%, 
    
    transparent 70%, 
    rgba(73, 73, 73, 0.4) 70%, 
    rgba(73, 73, 73, 0.4) 72%, 
    transparent 72%, 
    
    transparent 80%, 
    rgba(73, 73, 73, 0.4) 80%, 
    rgba(73, 73, 73, 0.4) 82%, 
    transparent 82%, 
    
    transparent 90%, 
    rgba(73, 73, 73, 0.4) 90%, 
    rgba(73, 73, 73, 0.4) 92%, 
    transparent 92%
  );
    background-size: 20px 62.5px;
    background-repeat: repeat-y;
    height: 250px;
    
    /* only needed for labels */
    width: 20px;
    font-size:0;
    margin:0;
    margin: auto;
    display: inline-block;

    padding:0;
  }


  .leftAndRightTickMarks {
    width: 291px;
    height: 250px;
    margin: auto;
  }



  
  .botTickMarks {
    background-color: #ffffff;
    background-image: 
      linear-gradient(90deg, 
        rgb(0, 0, 0) 0, 
        rgb(0, 0, 0) 2%, 
        transparent 2%
      ), 
      linear-gradient(90deg, 
        transparent 98%,
        rgba(0, 0, 0) 99%, 
        rgb(0, 0, 0) 100%
      ),
      linear-gradient(0deg, 
        #ffffff 50%, 
        transparent 50%
      ), 
      linear-gradient(90deg, 
        transparent 50%, 
        rgba(73, 73, 73, 0.5) 50%, 
        rgba(73, 73, 73, 0.5) 52%, 
        transparent 52%
      ), 
      linear-gradient(0deg, 
        #ffffff 70%, 
        transparent 70%
      ), 
      linear-gradient(90deg, 
        transparent 10%,
        rgba(73, 73, 73, 0.4) 10%, 
        rgba(73, 73, 73, 0.4) 12%, 
        transparent 12%, 
        
        transparent 20%,
        rgba(73, 73, 73, 0.4) 20%, 
        rgba(73, 73, 73, 0.4) 22%, 
        transparent 22%, 
        
        transparent 30%, 
        rgba(73, 73, 73, 0.4) 30%,
        rgba(73, 73, 73, 0.4) 32%, 
        transparent 32%, 
        
        transparent 40%, 
        rgba(73, 73, 73, 0.4) 40%, 
        rgba(73, 73, 73, 0.4) 42%, 
        transparent 42%, 
        
        transparent 60%, 
        rgba(73, 73, 73, 0.4) 60%, 
        rgba(73, 73, 73, 0.4) 62%, 
        transparent 62%, 
        
        transparent 70%, 
        rgba(73, 73, 73, 0.4) 70%, 
        rgba(73, 73, 73, 0.4) 72%, 
        transparent 72%, 
        
        transparent 80%, 
        rgba(73, 73, 73, 0.4) 80%, 
        rgba(73, 73, 73, 0.4) 82%, 
        transparent 82%, 
        
        transparent 90%, 
        rgba(73, 73, 73, 0.4) 90%, 
        rgba(73, 73, 73, 0.4) 92%, 
        transparent 92%
      );
    background-size: 62.5px 20px;
    background-repeat: repeat-x;
    min-height: 20px;
    
    /* only needed for labels */
    max-width: 250px;
    white-space:nowrap;
    font-size:0;
    margin:0;
    margin: auto;
    padding:0;
  }


  @keyframes slideInFromLeft {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  
  @keyframes slideInFromRight {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .slideInLeft {  
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
  }


  .slideInRight {  
    /* This section calls the slideInFromLeft animation we defined above */
    float: right;
    animation: 0.5s ease-out 0s 1 slideInFromRight;
  }



  @media only screen and (max-width: 720px) {
    .listCard {
        width: 300px;
        margin: 30px auto;
    }
    .originalImageTick {
      height: 200px;
    }

    .topTickMarks {
      background-size: 50px 15px;
      max-width: 200px;
      min-height: 15px;
    }
    .leftTickMarks {
      background-size: 15px 50px;
      max-height: 200px;
      width: 15px;
    }
    .rightTickMarks {
      background-size: 15px 50px;
      max-height: 200px;
      width: 15px;
    }
    .botTickMarks {
      background-size: 50px 15px;
      max-width: 200px;
      min-height: 15px;
    }
    .leftAndRightTickMarks {
      width: 230px;
      height: 200px;
      margin: auto;
    }  
}
