.cardTitle {
    color: white;
    font-size:5vh;
    padding:0px;
    text-align: center;
}

#stepSpan {
    background-color: #9adcff;
    color: black;
    padding: 8px;
    border-radius: 5px;
}

.Dashboard {
    min-height: 94vh;
    display: flex;
    flex-direction: column;
}

.createMosaicBtn {
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: .4em;
    border: 1px solid transparent;
    font-size: 16px;
}

p {
    margin-bottom: 5px;
}

.mosaicCard {
    width: 300px;
    margin: 30px;
}

@media only screen and (max-width: 720px) {
    .mosaicCard {
        width: 300px;
        margin: 15px auto;
    }
}