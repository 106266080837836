.Logo {
    color: white;
    font-size: 7vh;
     text-align: center;
     filter: drop-shadow(0px 4px 2px black);
    padding: 100px 0px 0px 0px;
    margin: 0px;
}

.logoBox {
    background-color: rgba(25,25,25,0.5);
    height: 100vh;
}

.ErrorMessage{
    color: white;
    font-family: 'Londrina Solid', cursive;
    font-size:150px;
    text-align: center;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #000000;
    filter: drop-shadow(0px 4px 2px black);
   margin: 0px;
}

.vertical .button {
    align-self: center;
}

#stepSpan {
    background-color: #9adcff;
    color: black;
    padding: 8px;
    border-radius: 5px;
}

.emoji {
    font-weight: 400;
    font-family: apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol;
}
.SubText{
    font-style: normal;
    font-weight: normal;
    font-size: 4vh;
    text-align: center;
    margin: 0px;
    padding: 0px 0px 15px 0px;

    color: white;
    -webkit-text-stroke-color: #000f9e;
    filter: drop-shadow(0px 3px 1.5px black);
}

#loginBtn {
    border-width:0px;
    background-color: white;
    width: 250px;
    color: black;
    padding : 10px 30px;
    margin: 0px 0px 10px 0px;
    font-size:35px;
    transition-duration: 0.4s;
    box-shadow: 0px 2px 4px black;
}  
.button:hover{
    background-color: #6DCBFF;
}

.App {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.copyright {
    color: white;
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100vw;
}