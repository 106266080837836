.NavBarLogo {
    color: white;
    font-size: 4vh;
    padding:0px;
    margin: 0px;
    white-space: nowrap;
}


.OnlineText {
  color: white;
    font-family: 'Londrina Solid', cursive;
    font-size:40px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #4B7FE6;
    padding:0px;
    display: inline;
}

.OnlineTextSpecial {
  color: rgba(255,178,56, 1);
    font-family: 'Londrina Solid';
    font-size:40px;
    padding:0px;
    display: inline;
}

.NavBarLogo:hover{
    color: #aaa;
}

button.signOutBtn {
    color: black;
    padding: 1vh;
    text-decoration: none;
    display: block;
    width: 100%;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(1,1,1,0.2);
    z-index: 3;
    border: 0px;
    border-radius: 5px;
}

a:hover{
    text-decoration: none
}

.centered {
    width:100%;
    margin:0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.doorBtn {
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 0;
    border: 0px solid transparent;
    width: 4vh;
    height: 4vh;
}

.doorBtn > img {
    width: 3vh;
    height: 3vh;
    margin: 0;
}

.outer_navbar{
    width:100%;
    margin:0px;
    justify-content: center;
    align-items: center;

}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    display: inline-block;
    width: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
}

/* Buttons inside the dropdown */
/* .dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(1,1,1,0.2);
    z-index: 3;
    border-radius: 0px;
} */

/* Change color of dropdown links on hover */
.dropdown-content button:hover {
    background-color: #aaa;
    box-shadow: 0px 8px 16px 0px rgba(1,1,1,0.2);
    z-index: 3;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
.navBar{
   background-color: "rgb(23,40,74)";
   height: 6vh;
}